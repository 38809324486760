<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'รายการบัญชีพัก' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <Banktemporary />
      </div>
    </vs-tab>

    <vs-tab v-if="$store.state.AppActiveUser.permissions.setting.action" icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'เพิ่มบัญชีพัก' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <insertbank />
      </div>
    </vs-tab>
  </vs-tabs>


</template>

<script>
import Banktemporary from './Banktemporary.vue'
// import Bankscbonly from './Bankscbonly.vue'
// import Bankkbank from './Bankkbank.vue'
// import Bankkbankonly from './Bankkbankonly.vue'
// import Bankbay from './Bankbay.vue'
import Insertbank from './InsertBank.vue'
export default {
  components: {
    Banktemporary,
    // Bankscbonly,
    // Bankkbank,
    // Bankbay,
    Insertbank
    // Bankkbankonly
  },
  data () {
    return {
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
